import React from 'react';
import { Image } from 'react-bootstrap';
import styles from '../../styles/toolkit/TrustPilot.module.scss';

const TrustPilot: React.FC = () => {
  return (
    <div className={`${styles['trustpilot']} d-flex justify-content-center`}>
      <span>Excellent</span>
      <Image
        src="/images/stars-5.svg"
        alt=""
        className={`${styles['stars']}`}
      />
      <Image
        src="/images/Trustpilot_logo.png"
        alt=""
        className={`${styles['logo']}`}
      />
    </div>
  );
};

export default TrustPilot;
