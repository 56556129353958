import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from './index';
import {
  QuoteStep,
  selectActiveQuoteStep,
  selectQuoteSteps,
  selectQuoteEstMoveIn,
  setQuoteStep,
  setQuoteLoading,
  selectQuoteLoading,
  selectQuotePostalCode,
} from '../store/quoteSlice';
import useNotify from '../hooks/useNotify';

interface QuoteRouter {
  loading: boolean;
  quoteLocation: string | null;
  quoteDescriptor: string | null;
  quoteEstMoveIn: string | null;
  quotePostalCode: string | null;
  activeStep: QuoteStep | null;
  setStep: (stepId: number) => void;
  nextStep: () => void;
  prevStep: () => void;
}

const useQuoteRouter = (): QuoteRouter => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { notify } = useNotify();
  const loading = useAppSelector(selectQuoteLoading);
  const steps = useAppSelector(selectQuoteSteps);
  const quoteLocation = useAppSelector((state) => state.quote.location);
  const quoteDescriptor = useAppSelector((state) => state.quote.descriptor);
  const quoteEstMoveIn = useAppSelector(selectQuoteEstMoveIn);
  const quotePostalCode = useAppSelector(selectQuotePostalCode);
  const activeStep = useAppSelector(selectActiveQuoteStep);

  const setStep = (stepId: number) => {
    const next = steps.find((s) => s.id === stepId);

    if (!next) {
      return;
    }

    dispatch(setQuoteLoading(true));

    dispatch(setQuoteStep(next.id))
      .unwrap()
      .then(() => {
        if (activeStep && activeStep.id !== next.id) {
          router.push(next.url);
        }
      })
      .catch((back) => {
        notify({
          type: 'danger',
          message: 'Please ensure all steps are valid before continuing',
        });
        router.push((steps[back] ?? steps[0]).url);
      })
      .finally(() => {
        dispatch(setQuoteLoading(false));
      });
  };

  return {
    loading,
    quoteLocation,
    quoteDescriptor,
    quoteEstMoveIn,
    quotePostalCode,
    activeStep: activeStep || null,
    setStep,
    nextStep: () => activeStep && setStep(activeStep.id + 1),
    prevStep: () => activeStep && setStep(activeStep.id - 1),
  };
};

export default useQuoteRouter;
