import React from 'react';
import Image from 'next/image';
import QuoteSteps from './QuoteSteps';
import styles from '../../styles/Navbar.module.scss';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar: React.FC = () => {
  return (
    <div
      className={`d-flex flex-row justify-content-between align-items-center ${styles['navbar']}`}
      style={{ boxShadow: '0 0 0.5rem 0.25rem rgba(0, 0, 0, 0.125)' }}
    >
      <a href="https://cinchstorage.co.uk">
        <Image
          alt="Cinch Self Storage"
          src="/images/logo.png"
          width={181}
          height={55}
        />
      </a>
      <QuoteSteps className="d-none d-lg-flex flex-grow-1 mx-4" />
      <a
        href="https://cinchstorage.co.uk"
        className={'text-decoration-none text-end'}
        style={{ cursor: 'pointer', width: 181 }}
      >
        <div className={'d-none d-lg-inline text-muted'}>Back to site</div>
        <span className={'d-inline d-lg-none'}>
          <FontAwesomeIcon icon={faXmark} size={'2x'} color={'#064dc1'} />
        </span>
      </a>
    </div>
  );
};

export default Navbar;
