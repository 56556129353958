import React from 'react';
import { ToastContainer } from 'react-bootstrap';
import { useAppSelector } from '../../../hooks';
import { selectNotifications } from '../../../store/notifySlice';
import Notification from './Notification';
import styles from '../../../styles/toolkit/notify/Notify.module.scss';

const Notify: React.FC = () => {
  const notifications = useAppSelector(selectNotifications);

  return (
    <ToastContainer
      position="bottom-end"
      className={styles['notify-container']}
    >
      {notifications.map(({ id, ...props }) => (
        <Notification key={id} id={id} {...props} />
      ))}
    </ToastContainer>
  );
};

export default Notify;
