import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useQuoteRouter } from '../../hooks';
import { setQuoteLocation } from '../../store/quoteSlice';
import { formatAddress } from '../../entities/Location';
import { MapLocation } from './LocationMap';
import Button from '../toolkit/Button';
import styles from '../../styles/pages/Locations.module.scss';

const convertToMiles = (distance: number): number => distance / 1609.34;

interface LocationCardProps extends React.HTMLAttributes<HTMLDivElement> {
  location: MapLocation;
  active: boolean;
}

const LocationCard: React.FC<LocationCardProps> = ({
  location,
  active,
  className,
  onClick,
}) => {
  const dispatch = useAppDispatch();
  const { nextStep } = useQuoteRouter();
  const [loading, setLoading] = useState(false);

  const selectStore = () => {
    setLoading(true);
    dispatch(setQuoteLocation(location.locationCode));
    nextStep();
    setLoading(false);
  };

  return (
    <Card
      className={`${className} ${styles['location-card']} ${
        active ? styles['active'] : ''
      }`}
      onClick={onClick}
    >
      <Card.Body
        className="d-flex align-items-center justify-content-between"
        style={{ textAlign: 'left' }}
      >
        <div className={`${styles['card-content-column']}`}>
          <Card.Title className={styles['card-title']}>
            {location.name}
          </Card.Title>
          <span
            className={`${styles['text-location']}`}
            style={{ fontSize: '11pt', fontWeight: '100', color: 'grey' }}
          >
            {formatAddress(location)}
          </span>
          {!!location.distance && (
            <span
              className={styles['text-accent']}
              style={{ display: 'block' }}
            >
              {convertToMiles(location.distance).toFixed(2)} miles
            </span>
          )}
        </div>
        <div className="d-flex flex-column align-items-center position-relative">
          <Button
            loading={loading}
            onClick={selectStore}
            className={`py-2 px-3 ${styles['store-button']}`}
          >
            Select Store
            <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 5 }} />
          </Button>
          {active && (
            <span
              className="position-absolute"
              style={{ bottom: '-70%', opacity: 0.5, fontSize: 14 }}
            >
              Closest to you
            </span>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default LocationCard;
