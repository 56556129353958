import React from 'react';
import {
  Button as BSButton,
  ButtonProps as BSButtonProps,
} from 'react-bootstrap';
import styles from '../../styles/toolkit/Button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface ButtonProps extends BSButtonProps {
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  loading = false,
  className,
  ...props
}) => {
  return (
    <BSButton
      {...(loading ? { disabled: true } : {})}
      className={`${styles['btn']} ${loading ? styles['loading'] : ''} ${
        className ? className : ''
      }`}
      {...props}
    >
      <div className={styles['loading-icon']}>
        <FontAwesomeIcon icon={faCircleNotch} spin />
      </div>
      <div style={{ opacity: loading ? 0 : 100 }}>{props.children}</div>
    </BSButton>
  );
};

export default Button;
