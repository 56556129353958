import type { NextPage } from 'next';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import { useAppDispatch } from '../hooks';
import Layout from '../components/layout/Layout';
import LocationMap, { MapLocation } from '../components/toolkit/LocationMap';
import LocationCard from '../components/toolkit/LocationCard';
import { useQuoteRouter } from '../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    faCircleNotch,
    faMapLocationDot,
    faArrowLeft,
    faList,
} from '@fortawesome/free-solid-svg-icons';
import { setQuoteEstMoveIn, setQuotePostalCode } from '../store/quoteSlice';
import { useGetLocationsQuery } from '../store/locationSlice';
import 'react-datepicker/dist/react-datepicker.css';
import styles from '../styles/pages/Locations.module.scss';
import TrustPilot from '../components/toolkit/TrustPilot';
import { useGetUnitListQuery } from 'src/store/unitListSlice';

const UK_CENTER = {
    lat: 54.58253865,
    lng: -4.281754766,
};

const HomePage: NextPage = () => {
    const dispatch = useAppDispatch();
    const {
        loading: quoteLoading,
        quoteLocation: quoteLocationCode,
        quotePostalCode,
        quoteEstMoveIn,
    } = useQuoteRouter();

    const { data: locations = [], isSuccess: fetchLocationsSuccess, isFetching: fetchingLocations } = useGetLocationsQuery(undefined, {
        refetchOnMountOrArgChange: 900,
    });

    const [mapLoading, setMapLoading] = useState(true);
    const [mapLocations, setMapLocations] = useState<MapLocation[]>([]);
    const [sortedLocations, setSortedLocations] = useState<MapLocation[]>([]);
    const [activeLocation, setActiveLocation] = useState<MapLocation | null>(
        null
    );
    const [search, setSearch] = useState<string>();
    const searchRef = useRef<HTMLInputElement>(null);
    const [showMapView, setShowMapView] = useState(false);

    const quoteLocation = useMemo(
        () => locations.find((l) => l.locationCode === quoteLocationCode),
        [locations, quoteLocationCode]
    );
    
    const { refetch: fetchUnitList, isSuccess: getUnitListSuccess } = useGetUnitListQuery(
        activeLocation?.locationCode || skipToken,
        {
            refetchOnMountOrArgChange: true,
        }
    );

    useEffect(() => {
        setTimeout(() => {
            if (searchRef.current) {
                searchRef.current.focus();
            }
        }, 200);
    }, []);

    useEffect(() => {
        if (!fetchingLocations && fetchLocationsSuccess) {
            if (getUnitListSuccess) {
                fetchUnitList();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchLocationsSuccess, fetchingLocations, activeLocation, getUnitListSuccess])

    useEffect(() => {
        if (mapLocations.length === 0 && !mapLoading) {
            setMapLocations([...locations]);
        }
    }, [locations, mapLoading, mapLocations, setMapLocations]);

    useEffect(() => {
        if (!quoteLoading && !mapLoading && locations.length) {
            // Absolutely disgusting workaround, we need to search postal code on page load
            if (quotePostalCode && searchRef.current) {
                // Set search input to postal code
                setSearch(quotePostalCode);

                // Wait for the ***approximate*** amount of time it takes for Google Autocomplete to load
                setTimeout(() => {
                    if (searchRef.current) {
                        // Simulate an enter keydown event
                        const event = new KeyboardEvent('keydown', {
                            key: 'Enter',
                            keyCode: 13,
                            cancelable: false,
                            bubbles: true,
                        });

                        searchRef.current.dispatchEvent(event);

                        // Set postalCode to null so this only happens on the first load
                        dispatch(setQuotePostalCode(null));
                    }
                }, 1000);
            }

            if (quoteLocation) {
                const location = locations.find(
                    (l) => l.locationCode === quoteLocation.locationCode
                );

                if (location && window.google) {
                    changeActiveLocation(location, window.google.maps);
                }
            }
        }
    }, [quoteLoading, quoteLocation, locations, mapLoading]);

    const calculateDistances = useCallback(
        (
            position: google.maps.LatLngLiteral | google.maps.LatLng,
            maps: any
        ): MapLocation[] =>
            mapLocations.map((l) => ({
                ...l,
                distance: maps.geometry.spherical.computeDistanceBetween(position, {
                    lat: l.latitude,
                    lng: l.longitude,
                }),
            })),
        [mapLocations]
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const placeChanged = (place: google.maps.places.PlaceResult, maps: any) => {
        setSearch(place?.formatted_address);

        if (place.name) {
            const compareLocations = calculateDistances(
                place.geometry!.location!,
                maps
            );

            setActiveLocation(
                compareLocations.reduce((acc, curr) => {
                    return curr.distance! < acc.distance! ? curr : acc;
                }, compareLocations[0])
            );
            setMapLocations(compareLocations);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changeActiveLocation = (location: MapLocation | null, maps: any) => {
        setActiveLocation(location);

        if (location) {
            const compareLocations = calculateDistances(
                { lat: location.latitude, lng: location.longitude },
                maps
            );
            setMapLocations(compareLocations);
        } else {
            setMapLocations(mapLocations.map((l) => ({ ...l, distance: undefined })));
        }
    };

    useEffect(() => {
        if (mapLocations.length > 0 && !mapLoading) {
            setSortedLocations(
                [...mapLocations].sort((a, b) => {
                    if (a.distance !== undefined && b.distance !== undefined) {
                        return a.distance - b.distance;
                    }
                    return a.locationCode.localeCompare(b.locationCode);
                })
            );
        }
    }, [mapLocations, mapLoading]);

    const handleSearchBtn = () => {
        if (searchRef.current) {
            searchRef.current.focus();

            const event = new KeyboardEvent('keydown', {
                key: 'Enter',
                keyCode: 13,
                cancelable: true,
                bubbles: true,
            });

            searchRef.current.dispatchEvent(event);
        }
    };

    return (
        <Layout fill loading={quoteLoading} fullWidth={showMapView} className="g-0">
            <Row className="h-100 position-relative gx-0">
                <Col lg={6} className={`h-100 ${styles['location-list-column']}`}>
                    <div className="d-flex flex-column h-100">
                        <div className="text-center">
                            <h2 className="w-100 m-auto pt-2">
                                Find your nearest store<br></br>to get a self storage quote
                            </h2>
                            <Form.Group className={`${styles['location-form']}`}>
                                <Form.Control
                                    ref={searchRef}
                                    placeholder="Postcode or location"
                                    value={search}
                                    className={`${styles['input']} ${styles['postcode-input']}`}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <DatePicker
                                    isClearable
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Est. move in"
                                    minDate={new Date()}
                                    className={`form-control ${styles['input']} h-100`}
                                    wrapperClassName={`${styles['date-input']}`}
                                    selected={
                                        quoteEstMoveIn ? parseISO(quoteEstMoveIn) : undefined
                                    }
                                    onChange={(val) => dispatch(setQuoteEstMoveIn(val))}
                                />
                                <Button
                                    className={`${styles['form-submit']}`}
                                    style={{ borderRadius: 7, fontSize: 18 }}
                                    onClick={handleSearchBtn}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                            <div className="d-md-flex justify-content-between align-items-center mt-4">
                                <span>
                                    or{' '}
                                    <a
                                        className="link-primary"
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeActiveLocation(null, window.google.maps);
                                        }}
                                    >
                                        see all locations
                                    </a>
                                </span>
                                <TrustPilot />
                            </div>
                            <div className="d-md-none" style={{ textAlign: 'right' }}>
                                <span
                                    onClick={() => setShowMapView(true)}
                                    className="text-muted"
                                    style={{ fontSize: '1.1rem' }}
                                >
                                    <FontAwesomeIcon
                                        icon={faMapLocationDot}
                                        color="#f90"
                                        style={{ marginRight: '.5rem' }}
                                    />
                                    View on map
                                </span>
                            </div>
                        </div>
                        <div
                            className="mt-3 mt-md-4 flex-grow-1"
                            style={{ overflowY: 'auto' }}
                        >
                            <div
                                style={{ marginRight: '.5rem' }}
                                className={
                                    mapLocations.length === 0
                                        ? 'd-flex align-items-center justify-content-center'
                                        : ''
                                }
                            >
                                {sortedLocations.length === 0 ? (
                                    <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
                                ) : (
                                    sortedLocations.map((location) => (
                                        <LocationCard
                                            key={location.locationCode}
                                            location={location}
                                            onClick={() =>
                                                changeActiveLocation(location, window.google.maps)
                                            }
                                            active={
                                                !!activeLocation &&
                                                activeLocation.locationCode === location.locationCode
                                            }
                                            className="mb-3"
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col
                    lg={6}
                    className={`h-100 ${styles['location-map-container']} ${showMapView ? styles['show'] : ''
                        }`}
                >
                    <div
                        className={'position-relative'}
                        style={{ width: '100%', height: '100%' }}
                    >
                        <div className={`d-md-none ${styles['map-back']}`}>
                            <span
                                className="map-back-btn"
                                onClick={() => setShowMapView(false)}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                            </span>
                        </div>
                        <div className={`d-md-none ${styles['map-all']}`}>
                            <span
                                className={styles['map-all-btn']}
                                onClick={() => {
                                    changeActiveLocation(null, window.google.maps);
                                }}
                            >
                                View all{' '}
                                <FontAwesomeIcon
                                    icon={faList}
                                    style={{ marginLeft: '0.5rem' }}
                                />
                            </span>
                        </div>
                        <LocationMap
                            initialCenter={UK_CENTER}
                            initialZoom={6}
                            placeChanged={placeChanged}
                            locations={mapLocations}
                            activeLocation={activeLocation}
                            onActiveLocationChange={changeActiveLocation}
                            searchRef={searchRef}
                            onReady={() => setMapLoading(false)}
                        />
                    </div>
                </Col>
            </Row>
        </Layout>
    );
};

export default HomePage;
