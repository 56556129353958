import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import { Notification, removeNotification } from '../../../store/notifySlice';
import { useAppDispatch } from '../../../hooks';

const Notification: React.FC<Notification> = ({
  id,
  type,
  message,
  duration,
  canClose,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      dispatch(removeNotification(id));
    }, 500);
  };

  return (
    <Toast
      show={open}
      delay={duration}
      autohide
      animation
      onClose={handleClose}
      bg={type}
    >
      <Toast.Body className="text-light">{message}</Toast.Body>
    </Toast>
  );
};

export default Notification;
