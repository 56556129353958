import React, { HTMLAttributes } from 'react';
import styles from '../../styles/QuoteStep.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useQuoteRouter } from '../../hooks';

interface QuoteStepProps extends HTMLAttributes<HTMLDivElement> {
  index: number;
  name: string;
  href: string;
  active: boolean;
  valid: boolean;
}

const QuoteStep: React.FC<QuoteStepProps> = ({
  name,
  index,
  active,
  valid,
  style,
  className,
}) => {
  const { setStep } = useQuoteRouter();

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setStep(index);
  };

  return (
    <div onClick={handleClick}>
      <div className={`${styles['quote-step']} ${className}`} style={style}>
        <div
          className={`${styles['quote-step--badge']} ${
            active ? styles['active'] : ''
          } ${valid ? styles['valid'] : ''}`}
        >
          <span>
            {valid && !active ? <FontAwesomeIcon icon={faCheck} /> : index}
          </span>
        </div>
        <span className={styles['quote-step--name']}>{name}</span>
      </div>
    </div>
  );
};

export default QuoteStep;
