import React from 'react';
import { useAppSelector } from '../../hooks';
import QuoteStep from './QuoteStep';
import { selectQuoteSteps } from '../../store/quoteSlice';

const QuoteSteps: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
}) => {
  const quoteSteps = useAppSelector(selectQuoteSteps);

  return (
    <div
      className={`d-flex flex-row justify-content-center ${className} gap-5`}
    >
      {quoteSteps.map((step) => (
        <QuoteStep
          key={step.name}
          index={step.id}
          name={step.name}
          href={step.url}
          active={step.active}
          valid={step.valid}
        />
      ))}
    </div>
  );
};

export default QuoteSteps;
